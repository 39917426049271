<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="网站域名" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="domain">
            <a-input v-model="form.domain" placeholder="请输入网站域名..." />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="国家" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="country">
            <a-input v-model="form.country" placeholder="请输入国家..." />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="客户邮箱" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="email">
            <a-input v-model="form.email" placeholder="请输入客户邮箱..." />
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="订单状态" :labelCol='labelcol' :wrapperCol="wrappercol" prop="status">
            <a-select v-model="form.status" placeholder="请选择订单状态..." :options='statusList'>
            </a-select>
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="支付类型" :labelCol='labelcol' :wrapperCol="wrappercol" prop="pay_type">
            <a-select v-model="form.pay_type" placeholder="请选择支付类型..." :options='pay_typeList'>
            </a-select>
          </a-form-model-item>
        </a-col>

	      <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="开始日期" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="start_time">
            <a-date-picker placeholder="选择日期" v-model="form.start_time" @change="onChangeStartDate" format="YYYY-MM-DD HH:mm:ss" />
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="6">
          <a-form-model-item label="结束日期" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="end_time">
            <a-date-picker placeholder="选择日期" v-model="form.end_time" @change="onChangeEndDate" format="YYYY-MM-DD HH:mm:ss" />
          </a-form-model-item>
        </a-col>

        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">

            <a-button type="primary" @click="search">
              搜索
            </a-button>
            <a-button @click="reset('ruleForm')">重置</a-button>

          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>

    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">查询表格</div>
        <div class="operate">

          <a-tooltip placement="top">
            <template slot="title">
              <span>下载</span>
            </template>
            <a class="ant-dropdown-link" style="display:block;margin-right:10px;" @click="start" :disabled="!selectedRowKeys.length > 0">
              <a-icon type="vertical-align-bottom" />
            </a>
          </a-tooltip>

          <a-tooltip placement="top">
            <template slot="title">
              <span>表格大小</span>
            </template>
            <a-dropdown :trigger="['click']" style="margin-right:10px;">
              <a class="ant-dropdown-link" style="display:block">
                <a-icon type="column-height" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="tableSize='default'">默认</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='middle'">中等</a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="tableSize='small'">紧凑</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </a-tooltip>

        </div>
      </div>

      <a-table
          :size="tableSize"
          :scroll="{ x: true }"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :columns="columns"
          :row-key="record => record.order_id"
          :data-source="data"
          :pagination="pagination"
          :loading="loading"
          bordered

          @change="handleTableChange"
      >
      <template slot="username" slot-scope="data,record">
        {{record.last_name}} {{record.first_name}}
      </template>

        <template slot="status" slot-scope="data,record">
          <a-tag :color="record.status=='success'?'green':record.status=='failed'?'red':'orange'">
            {{record.status|statusFilter}}
          </a-tag>
        </template>

        <template slot="pay_type" slot-scope="data,record">
          {{record.pay_type|pay_typeFilter}}
        </template>
        <!-- 操作单元格 -->
        <template slot="操作" slot-scope="data">
          <span>
            <a-button size="small" type="primary" @click="model_address(data)">查看地址</a-button>
          </span>
          <span>
            <a-button size="small" type="primary" @click="model_product(data)">查看产品</a-button>
          </span>
        </template>
      </a-table>
    </div>

    <!-- 查看地址跟产品模态框 -->
    <a-modal
      :title="view_title"
      :visible="viewModalVisible"
      @cancel="cancelView"
      width="960px"
      :footer="null"
    >
      <a-table
          size="small"
          :columns="view_title=='查看地址'?address_columns:product_columns"
          :row-key="record => record.id"
          :data-source="view_title=='查看地址'?addressData:productData"
          bordered
        >

          <template slot="address" slot-scope="data,record">
              <div class="user"><span class="span">地址：</span><span class="val">{{record.address1}} {{record.address2}}</span></div>
              <div class="user"><span class="span">城市：</span><span class="val">{{record.city}}</span></div>
              <div class="user"><span class="span">州/省份：</span><span class="val">{{record.state}}</span></div>
              <div class="user"><span class="span">邮编：</span><span class="val">{{record.zip_code}}</span></div>
              <div class="user"><span class="span">电话：</span><span class="val">{{record.telephone}}</span></div>
          </template>
          <template slot="image" slot-scope="data,record">
            <img style="width:100px;height:100px;" :src="data">
          </template>
          <!-- 操作单元格 -->
          <!-- <template slot="操作" slot-scope="data">
                <span>
                  <a-button size="small" type="primary"  @click="onDetails(data)">查看详情</a-button>
                </span>
          </template> -->
        </a-table>
        
    </a-modal>


  </div>
</template>
<script>
import  {getOrderList,OrderProduct} from "@/axios/order.js"
import {myMixin} from "@/static/js/mixin.js"
import moment from "moment";
const columns = [//列描述

  {
    align:'center',
    title: '域名',
    dataIndex: 'domain',
  },
  {
    align:'center',
    title: '订单号',
    dataIndex: 'order_sn',
  },
  {
    align:'center',
    title: '金额',
    dataIndex: 'total',
  },
  {
    align:'center',
    title: '货币',
    dataIndex: 'currency',
  },
  {
    align:'center',
    title: '国家',
    dataIndex: 'country',
  },
  {
    align:'center',
    title: '客户名称',
    dataIndex: 'id',
    scopedSlots: { customRender: 'username', },
  },
  {
    align:'center',
    title: '邮箱',
    dataIndex: 'email',
  },
  {
    align:'center',
    title: '订单状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status', },
  },
  {
    align:'center',
    title: '付款方式',
    dataIndex: 'pay_type',
    scopedSlots: { customRender: 'pay_type', },
  },
  {
    align:'center',
    title: '订单时间',
    dataIndex: 'create_time',
  },



  {
    align:'center',
    title: '操作',
    // dataIndex: 'status',
    scopedSlots: { customRender: '操作', },
  },
];
const address_columns = [//列描述

  {
    align:'center',
    title: 'IP',
    dataIndex: 'customer_ip',
  },
  {
    align:'center',
    title: 'UA',
    dataIndex: 'customer_ua',
    // width:'400px'
  },

  {
    align:'center',
    title: '地址',
    scopedSlots: { customRender: 'address', },
  },

];
const product_columns = [//列描述

  {
    align:'center',
    title: '商品ID',
    dataIndex: 'product_id',
  },
  {
    align:'center',
    title: '商品图片',
    dataIndex: 'image',
    scopedSlots: { customRender: 'image', },
  },
  {
    align:'center',
    title: '商品名称',
    dataIndex: 'product_name',
  },
  {
    align:'center',
    title: '商品数量',
    dataIndex: 'product_quantity',
  },
  {
    align:'center',
    title: '商品规格',
    dataIndex: 'product_attribute',
  },
  {
    align:'center',
    title: '价格',
    dataIndex: 'price',
  },
  {
    align:'center',
    title: '小计',
    dataIndex: 'subtotal',
  },
 

];
export default {

  filters: {

    statusFilter(status) {
      const statusMap = {
        "unpaid": '未支付',
        "success": '成功',
        "failed": '失败',
      }

      return statusMap[status]
    },
    pay_typeFilter(status) {
      const statusMap = {
        "pp": 'Paypal',
        "stripe": 'Stripe',
        "bank": '银行汇款',
        "mpay": '小额支付',
        'visa':'Visa',
      }

      return statusMap[status]
    },
  },
  mixins: [myMixin],
  data() {

    return {

      statusList: [
        {
            value: "",
            label: '全部'
        },
        {
            value: "unpaid",
            label: '未支付'
        },
        {
            value: "success",
            label: '成功'
        },
        {
            value: "failed",
            label: '失败'
        },
      ],
      pay_typeList: [
        {
            value: "",
            label: '全部'
        },
        {
            value:"PP",
            label: 'Paypal'
        },
        {
          value: "visa",
          label: 'Visa'
        },
        {
            value: "stripe",
            label: 'Stripe'
        },
        {
            value: "bank",
            label: '银行汇款'
        },
        {
            value: "mpay",
            label: '小额支付'
        },
      ],    
      form: {
        page_size: 10,
        page:1,
        domain:'',
        email:'',
        country:'',
	      start_time:'',
        end_time:'',
        status:'',
        pay_type:'',
      },

      columns,//列描述
      address_columns,
      product_columns,
      view_title:'查看地址',//查看模态框
      viewModalVisible:false,

      addressData:[],
      productData:[],
    }

  },
  created() {
    this.form.start_time=this.moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss')
    this.form.end_time=this.moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm:ss')
  },
  mounted() {
    let that=this
    this.fetch();
  },
  methods:{
    moment,
    onChangeStartDate(date, dateString) {//时间
      this.form.start_time=dateString;
    },
    onChangeEndDate(date, dateString) {//时间
      this.form.end_time=dateString;
    },
    async fetch (params = {}) {

      this.loading = true;
      let data=await getOrderList({...params,...this.form})
      this.loading = false;
      const pagination = { ...this.pagination };
      pagination.total = data.data.total;
      this.data = data.data.list;

      this.pagination = pagination;
    },
    handleTableChange(pagination, filters, sorter) {
      //pagination:分页信息，filters：筛选信息，sorter：排序信息
      this.pagination = { ...pagination };
      this.form.page_size=pagination.pageSize
      this.form.page=pagination.current
      console.log(this.pagination,pagination)

      this.fetch({
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },

    model_address(data){
      this.view_title='查看地址'
      this.addressData=[];
      this.addressData.push({
        id:data.id,
        country:data.country,
        city:data.city,
        address1:data.address1,
        address2:data.address2,
        customer_ip:data.customer_ip,
        customer_ua:data.customer_ua,
        state:data.state,
        zip_code:data.zip_code,
        telephone:data.telephone
      })
      this.viewModalVisible=true
    },
    model_product(data){

      this.view_title='查看产品'
      
      console.log(this.productData)
      OrderProduct({orderId:data.id}).then((res)=>{
        this.productData=res.data
        this.viewModalVisible=true
      })
    },
    cancelView(){
      this.viewModalVisible=false
      this.productData=[];
    },

  }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
.user{width:200px}
.user .span{width:80px;display: inline-block;text-align: right;}
.user .val{min-width:100px;text-align: left;display: inline-block;}
::v-deep .ant-table-small > .ant-table-content > .ant-table-body{margin:0;}
</style>

