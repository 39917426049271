
import {get,post} from "@/axios";

//订单页面
export function getOrderList(data) {
    return get('api/order/list',data);
}

//订单的产品信息
export function OrderProduct(data) {
    return get('api/order/OrderProduct',data);
}

